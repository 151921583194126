.shrink img {
  transition: 1s ease;
}

.shrink img:hover {
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  transition: 1s ease;
}

.form-position {
  margin-top: 200px;
  margin-bottom: 200px;
}

.admin-accordion {
  margin-top: 70px;
}

.image-product {
  width: 100%;
  height: 300px;
}

.image-abaut {
  width: 100%;
  height: 500px;
}

@media only screen and (max-width: 576px) {
  .slider {
    height: 180px;
    overflow-y: hidden;
  }
}
